
import { defineComponent, onMounted, ref } from 'vue';
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import EmBreve from './components/EmBreve.vue'
import MyFooter from './components/MyFooter.vue'
import MyForm from './components/MyForm.vue'
import IntroWebsite from './components/IntroWebsite.vue'


export default defineComponent({
  components: {
    EmBreve,
    MyFooter,
    MyForm,
    IntroWebsite
  },

  async mounted() {
    const locomotiveScroll = (await import('locomotive-scroll')).default;
    const LocomotiveScroll = new locomotiveScroll();
  },

  name: 'App',
  data() {
    return {
      workText: 'Let´s work together?'
    }
  },



  setup() {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline()


    onMounted(() => {

      const panel = gsap.utils.toArray(".panel")
      const container = document.querySelector(".double-container")
      let topScroll = window.innerWidth < 2 ? 0 : "-200%";

      gsap.to("body", {
        scrollTrigger: {
          trigger: '.letter-h1',
          scrub: 2,
          start: "center top",
          end: "+=800"
        }, backgroundColor: "#f2f2f2"
      }),
  
      //LETRAS h1

        tl.set(".letter-h1", { y: 0 }),
        tl.to(".letter-h1", {
          scrollTrigger: {
            trigger: '.letter-h1',
            scrub: 2,
            start: "150% 49%",
            end: "+=200",
          },
          opacity: 0,
          stagger: {
            each: 0.10,
            from: "random"
          }
          //FRASE ABAIXO
        }).to(".progress", {
          scrollTrigger: {
            trigger: ".progress",
            scrub: 0.5,
            start: "center 20%",
            end: "+=150",
          }, opacity: 0
          //BLOCO TODO
        }).to(".part-1",
          {
            scrollTrigger: {
              trigger: '.part-1',
              scrub: 1,
              start: "center 52%",
              pin: true,
            },
          }
        )
      tl.to(panel, {
        xPercent: -100 * (panel.length - 1),
        ease: "none",
        scrollTrigger: {
          trigger: ".double-container",
          pin: true,
          scrub: 1,
          end: "+=1000"
        },
        
        //HEADER 2
      }).to(".part-2 h2", {
        scrollTrigger: {
          trigger: container,
          scrub: 2,
          start: "top top",
          end: "+=1000"
        }, color: "#202020",
        keyframes: {
          "0%": { x: 0 },
          "50%": { x: "100%", y: 0 },
          "90%": { y: topScroll }
        }
        //FOOTER
      }).to(".social-footer a", {
        scrollTrigger: {
          trigger: container,
          scrub: 2,
          start: "top top",
        }, color: "#202020",

      })


    })
  }
});

