
import { defineComponent, ref } from 'vue'
import emailjs from '@emailjs/browser';

export default defineComponent({
    setup() {
        let isLoading = ref(false)

        const form = ref(null);

        const sendEmail = (e: any) => {
            e.preventDefault();
            isLoading.value = true;
            const currentForm = form.value;
            if (currentForm === null) return;

            emailjs.sendForm('service_g9jobfd', 'template_8bn97wn', currentForm, 'MSPIUEWF69QcjnYel')

                .then((result) => {
                    isLoading.value = false;
                    console.log(isLoading)
                    const formulario = document.querySelector('.form-content');
                    const obri = document.querySelector('.agradece');

                    console.log('SUCCESS!', result.text);
                    formulario?.classList.add('sended');
                    obri?.classList.add('display')
                    setTimeout(() => {
                        formulario?.classList.add('off-display')
                        obri?.classList.add('show')
                    }, 500)

                })

                .catch((error) => {
                    isLoading.value = false;
                    console.error('FAILED...', error.text);
                });
        };

        return {
            form,
            sendEmail,
            isLoading
        };
    }
})
