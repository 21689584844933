<template>
    <div class="footer">
      <div class="social-footer">
        <a href="https://www.linkedin.com/in/alexandre-beltramini/" target="_blank">
          Linkedin
        </a>
        <a href="https://www.instagram.com/beltdev/" target="_blank">
          Instagram
        </a>
        <a href="https://www.behance.net/alexandrebeltramini" target="_blank">
          Behance
        </a>
      </div>
    </div>
</template>

<script>
</script>

<style lang="scss">
.footer {
  position: fixed !important;
  display: flex;
  width: 100%;
  justify-content: space-between;
  bottom: 0;

  .social-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 30px;
    color: #F5F5F5;

    a {
      color: #F5F5F5;
      text-transform: uppercase;
      text-decoration: none;
      opacity: 1;
      transition:0.3s;
    }
  }
  a:hover{
        opacity: 0.5 !important;
  
      }

}
</style>