<template>
<section id="smooth-content">

<div class="part-1">
  <h1>
    <div class="letter-h1">E</div>
    <div class="letter-h1">m </div>
    <div class="letter-h1">B</div>
    <div class="letter-h1">r</div>
    <div class="letter-h1">e</div>
    <div class="letter-h1">v</div>
    <div class="letter-h1">e</div>
    <div class="letter-h1">.</div>
  </h1>
  <p class="progress">Website <em>in</em> progress</p>
</div>

</section>
</template>

<script>
import gsap from 'gsap'
import {defineComponent} from 'vue'

export default defineComponent({

})
</script>

<style lang="scss">
.progress {
  font-size: 1.7vw;
  letter-spacing: 1.33vw;
  margin-top: 0;
}
h1 {
  color: var(--cinza);
  font-family: 'CotfordVar', serif !important;
  font-weight: 400;
  font-size: 10.5vw;
  margin: 0;
  width: 100%;
}

.part-1 {
  transform: translatey(-5vw) !important;
}

.letter-h1 {
  display: inline;
}
</style>