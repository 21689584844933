<template>
    <div class="intro">
        <h1 id="logo-intro">
            <span id="item-a" class="item-ab">A</span>
            <span class="item-ab">B</span>
        </h1>
    </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import gsap from 'gsap';

export default defineComponent({
    setup() {
        onMounted(() => {
            const tl = gsap.timeline();
            tl.set('.item-ab', {
                opacity: 0,

            })
            tl.to('.item-ab', {
                opacity: 1,
                delay: 0.4,
                duration: 1,
                stagger: {
                    each: 1
                }
            })
            tl.to('#logo-intro', {
                /*scale: 2,*/
                opacity: 0,
                ease: "power1"
            }),
            tl.to('.intro', {
                y: "-100vh"
            })
        })
    }
})
</script>
<style lang="scss">
.intro {
    z-index: 9000;
    background-color: var(--cinza);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    #logo-intro {
        color: var(--preto);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -75%);

        #item-a {
            font-weight: 200;
        }
    }

}
</style>